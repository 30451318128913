<div class="modal" role="dialog" aria-modal="true" tabindex="-1" aria-label="{{Title}}">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                {{#if ShowClose}}
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                {{/if}}
                <h2>{{Title}}</h2>
            </div>
            <div class="modal-focus-target">
                <div class="modal-body">
                    <p>{{{Body}}}</p>
                </div>
                <div class="modal-footer">
                    {{#if Button1}}
                    <a href="#" class="btn btn-primary button-1" data-dismiss="modal" role="button">{{Button1}}</a>
                    {{/if}}
                    {{#if Button2}}
                    <a href="#" class="btn btn-default button-2" data-dismiss="modal" role="button">{{Button2}}</a>
                    {{/if}}
                </div>
            </div>
        </div>
    </div>
</div>

{{! note: the fade class causes the background div to remain, thus hijacking all clicks on the page.  silly. }}

